document.addEventListener('DOMContentLoaded', () => {
   const stageBtns = document.querySelectorAll('[data-stage-btn]')
   const suffix = document.querySelector('[data-version-suffix]')

   // Function to update the suffix based on the version
   function updateSuffix(version) {
       if (version !== 'stable') {
           suffix.textContent = `-${version}`
           suffix.classList.remove('d-none')
       } else {
           suffix.classList.add('d-none')
       }
   }

   // Function to handle the click event
   function handleClick() {
       const version = this.getAttribute('data-stage-btn')
       updateSuffix(version)
   }

   // Add click event listeners to buttons
   stageBtns.forEach(btn => {
       btn.addEventListener('click', handleClick)
   })

   // Check the initial state on page load
   const checkedStageBtn = document.querySelector('input[name="stage"]:checked')
   if (checkedStageBtn) {
       const initialVersion = checkedStageBtn.value
       updateSuffix(initialVersion)
   }
})

const dropdowns = document.querySelectorAll('.cg-dropdown');

dropdowns.forEach(dropdown => {
    const dropdownId = dropdown.getAttribute('data-dropdown-id');
    const dropItems = dropdown.querySelectorAll('[data-value]');
    const button = dropdown.querySelector('[data-dropdown-btn]');
    const input = dropdown.querySelector('[data-dropdown-target]');

    dropItems.forEach(dropdownItem => {
        dropdownItem.addEventListener('click', () => {
            const value = dropdownItem.getAttribute('data-value');
            const text = dropdownItem.textContent;

            input.value = value;
            button.textContent = text;
        });
    });
});