const internalLinks = document.querySelectorAll('[data-link="internal"]')

internalLinks.forEach(link => {
   link.addEventListener('click', e => {
      e.preventDefault()
      const href = link.getAttribute('href')
      const target = document.querySelector(href)
      target.scrollIntoView({
         behavior: 'smooth',
         block: 'start'
      })
   })
})