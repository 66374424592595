// Select the elements
const elements = [
   document.querySelector('.navbar'),
   document.querySelector('.logo-wrapper'),
   document.querySelector('.nav-menu-wrapper')
]

// Function to toggle the class based on scroll position
function toggleClassOnScroll() {
   if (window.scrollY > 100)
       elements.forEach(element => element.classList.add('scrolled'))
   else
       elements.forEach(element => element.classList.remove('scrolled'))
}

// Add scroll event listener
window.addEventListener('scroll', toggleClassOnScroll)